@font-face {
  font-family: 'CreteRound';
  src: local('CreteRound'), url(./fonts/CreteRound-Regular.ttf) format('truetype');
}
body {
  margin: 0;
  font-family: CreteRound, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .root {
    position: relative;
  }
}