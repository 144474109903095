@import "../Welcome.scss";

.tag-line {
  position: absolute;
  left: $welcome-side-margin;
  top: $welcome-top-margin;
  color: white;
  transform: translateY(-50%);
}

.logo {
  width: 50vw;
}

.text-container {
  max-width: 70vw;
}

.text {
  font-size: 15pt;
}

span {
  display: inline-block;
}