@import "../Welcome.scss";

.social-media {
  z-index: 21;
  position: absolute;

  top: $welcome-top-margin;
  right: $welcome-side-margin;

  display: flex;
  flex-direction: column;

  transform: translateY(-50%);
}

.social-media a {
  text-align: right;
  font-size: 30pt;
  color: #fff;
  transition: 0.3s;
  margin: 5%;
  text-shadow: 0 0 3px #000;
}

.social-media a:hover {
  font-size: 55pt;
}