body {
  font-family: CreteRound, serif;
}

h1 {
  position: absolute;
  text-align: center;

  left:50vw;
  transform: translateX(-50%);

  top:100vh;
}