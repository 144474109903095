$umbrella-radius: 300vmax;
$umbrella-margin: -1 * $umbrella-radius / 2;
$filter-color: rgba(0, 0, 0, 1);

.wallpaper {
  position: absolute;
  height: 100vh;
  width: 100vw;
  bottom: 0;

  overflow: hidden;
  background-color: $filter-color;
}

.flash {
  z-index: 20;

  animation: flash 1s ease-out;
  background-color: rgba(0, 0, 0, 0);
}

#logo {
  margin: 0;
  position: absolute;
  width: 100vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

@keyframes flash {
  from {
    background-color: rgba(255, 255, 255, 1);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
}